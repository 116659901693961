
import axios from "axios"
import {Notification} from 'element-ui'
import {apiBaseUrl} from '@/constant/constant'


/*
更改关于文章id
更改github appid
*/
const client =  axios.create({
    baseURL: apiBaseUrl,
    timeout: 10000, 
    headers : {
    //   'Content-Type': 'application/x-www-form-urlencoded' // 配置请求头application/json
        'token':localStorage.token || ''
    },
    // send cookies when cross-domain requests
    // withCredentials: true,
});

client.interceptors.response.use(
    function(response) {
        const res = response.data;
        if (res.code === 2000) {
            return Promise.resolve(res)
        } 
        else if (res.code === 4200) { // token 过期
            Notification.error({title: '错误',message: '账号信息过期'});
            localStorage.removeItem('token')
            // window.location.reload()
        }
        // else if (res.code === 4202) { //未登录
        //     return Promise.resolve(res)
        // }
        else {
            Notification.error({title: '错误',message: res.msg,});
            return Promise.reject(res || 'Error');
        }
    },
    function(error) {
        console.log('网络请求发生错误',error)
        Notification.error({
            title: '错误',
            message: '网络请求发生错误',
        });
        return Promise.reject(error)
    }
)

export default function myAxios(config) {
    return client(config)
}