import myAxios from "./axiosConfig";

export function getAllCategory () {
    return myAxios({
        url:'/category',
        method:'get',
    })
}

export function addCategory (categoryName) {
    return myAxios({
        url:'/category',
        method:'post',
        data:{
            name:categoryName
        },
    })
}