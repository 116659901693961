import myAxios from "./axiosConfig";

export function getAllTag () {
    return myAxios({
        url:'/tag',
        method:'get',
    })
}


export function getTagsByCategoryId (categoryId) {
    return myAxios({
        url:`/tag/category/${categoryId}`,
        method:'get',
    })
}

// 添加标签
export function addTag (tagName) {
    return myAxios({
        url:`/tag`,
        method:'post',
        headers:{
            'Content-Type':'application/json;charset=UTF-8'
        },
        data:JSON.stringify({name:tagName})
    })
}

// 删除标签
export function deleteTag (tagId) {
    return myAxios({
        url:`/tag/${tagId}`,
        method:'delete',
    })
}