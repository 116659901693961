<template>
  <div id="app">
    <LayOut/>
  </div>
</template>

<script>
 import LayOut from '@/views/layout.vue'
 import MyImgViewer from "@/mixins/MyImgViewer/index";
 export default {
  mixins: [MyImgViewer],
    name: 'App',
    components: {
      LayOut
    },
    methods: {
    },
    mounted(){
      document.body.style.backgroundColor = localStorage.bodyBgColor || 'rgba(30, 144, 255, 0.2)';
    },
    created() {
      // 缓存分类和标签
      this.$store.dispatch('Cache/updateCategories')
      this.$store.dispatch('Cache/updateTags')
      // 登录
      if (!this.$store.state.User.isLogin) {
        this.$store.dispatch('User/login')
      }
    }
  }
</script>

<style>
@import '@/static/css/common.scss';

</style>
