import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/utils/request'
import './static/css/common.scss'

import {Pagination,Empty,Notification,Image,MessageBox,Tag,Dialog,Form,FormItem,Input,Upload,Radio,Switch,ColorPicker} from 'element-ui'
import {Table,TableColumn,Card} from 'element-ui'
Vue.use(Pagination)
Vue.use(Empty)
Vue.use(Image)
Vue.use(Tag)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Upload)
Vue.use(Radio)
Vue.use(Switch)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Card)
Vue.use(ColorPicker)
Vue.config.productionTip = false

require('@/utils/util.js')



Vue.prototype.$http = axios;
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
