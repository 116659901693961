// 本地配置
// export const filePostUrl = 'http://localhost/file/upload'
// export const apiBaseUrl = 'http://localhost:8080/'

// 修改服务器配置
export const apiBaseUrl = 'http://api.xgcode.cn/'
export const filePostUrl = 'http://api.xgcode.cn/file/upload'

export const aboutMeArticleId = 1

// 更改github appid