import axios from 'axios'

const client =  axios.create({
    // url = base url + request url
    // https://www.xgcode.cn/article/getForList?rows=&currentPage=1&title=&typeId=&categoryId=
    // baseURL: 'http://localhost:8080/xgblog_war_exploded/',
    baseURL: 'http://localhost/',
    timeout: 15000000, // request timeout
    // headers : {
    //   'Content-Type': 'application/x-www-form-urlencoded' // 配置请求头application/json
    // },
    // send cookies when cross-domain requests
    // withCredentials: true,
});



client.interceptors.response.use(
    function(response) {
        const res = response.data;
        if (res.code === 2000) {
            return Promise.resolve(res)
        } else {
            alert(res.msg)
            return Promise.reject(res || 'Error');
        }
    },
    function(error) {
        console.log('网络请求发生错误',error)
        // return Promise.reject(error)
    }
)



export default client;