import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/articles'
  },
  {
    path: '/articles/:id',
    name: 'ArticleDetail',
    component: () => import('../views/article/articleDetail.vue')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import('../views/article/articles.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/project/projects.vue')
  },
  {
    path: '/outline',
    name: 'Outline',
    component: () => import('../views/outline/outline.vue')
  },
  {
    path: '/aboutMe',
    name: 'AboutMe',
    component: () => import('../views/aboutMe/aboutMe.vue')
  },
  { // 留言
    path: '/leaveWord',
    name: 'LeaveWord',
    component: () => import('../views/leaveWord/leaveWord.vue')
  },
  { // 个人中心
    path: '/profile',
    name: 'Profile',
    redirect: '/profile/info',
    component: () => import('../views/profile/layout.vue'),
    children:[
      {
        path:'info',
        name:'Info',
        component: () => import('../views/profile/info.vue'),
      },
      {
        path:'userNotify',
        name:'UserNotify',
        component: () => import('../views/profile/userNotify.vue'),
      },
      {
        path:'systemNotify',
        name:'SystemNotify',
        component: () => import('../views/profile/systemNotify.vue'),
      },
      {
        path:'setting',
        name:'Setting',
        component: () => import('../views/profile/setting.vue'),
      },
    ]
  },
  // { // 发布文章
  //   path: '/postArticle',
  //   name: 'PostArticle',
  //   component: () => import('../views/article/postArticle.vue')
  // },
  // { // 系统
  //   path: '/system',
  //   name: 'System',
  //   redirect: '/system/index',
  //   component: () => import('../views/system/systemLayout'),
  //   children:[
  //     {
  //       path: 'index',
  //       name: 'SystemName',
  //       component: () => import('../views/system/index'),
  //     },
  //     {
  //       path: 'articleManage',
  //       name: 'ArticleManage',
  //       component: () => import('../views/system/articleManage'),
  //     },
  //     {
  //       path: 'commentManage',
  //       name: 'CommentManage',
  //       component: () => import('../views/system/commentManage'),
  //     },
  //     {
  //       path: 'categoryAndTagManage',
  //       name: 'CategoryAndTagManage',
  //       component: () => import('../views/system/categoryAndTagManage'),
  //     },
  //     {
  //       path: 'friendUrlManage',
  //       name: 'FriendUrlManage',
  //       component: () => import('../views/system/friendUrlManage'),
  //     },
  //     {
  //       path: 'userManage',
  //       name: 'UserManage',
  //       component: () => import('../views/system/userManage'),
  //     },
  //     {
  //       path: 'optionManage',
  //       name: 'OptionManage',
  //       component: () => import('../views/system/optionManage'),
  //     },
      
  //   ]
  // }
]

const router = new VueRouter({
  routes
})

export default router
