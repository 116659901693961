import myAxios from "./axiosConfig";

export function loginByToken (token) {
    return myAxios({
        url:'/user/login/token',
        method:'post',
    })
}

// 条件获取用户列表
export function getUser (conditionDto) {
    return myAxios({
        url:'/user',
        method:'get',
        params:conditionDto
    })
}

// 更新
export function updateUser (userDto) {
    return myAxios({
        url:'/user',
        method:'put',
        data:userDto
    })
}

// 删除
export function deleteUser (userId) {
    return myAxios({
        url:'/user/'+userId,
        method:'delete',
    })
}


export function updateEmail (newEmail) {
    return myAxios({
        url:'/user/email',
        method:'post',
        params: {
            newEmail
        }
    })
}

export function updateBlogUrl (newBlogUrl) {
    return myAxios({
        url:'/user/blogUrl',
        method:'post',
        params: {
            newBlogUrl
        }
    })
}


export function updateNickname (newNickname) {
    return myAxios({
        url:'/user/nickname',
        method:'post',
        params: {
            newNickname
        }
    })
}

export function updateSign (newSign) {
    return myAxios({
        url:'/user/sign',
        method:'post',
        params: {
            newSign
        }
    })
}