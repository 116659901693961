import Vue from 'vue'
import Vuex from 'vuex'
import {getAllCategory} from '@/apis/category'
import {getAllTag} from '@/apis/tag' 
import {loginByToken} from '@/apis/user'
import { Notification } from 'element-ui';
Vue.use(Vuex)

const Cache = {
  namespaced: true,	// 开启命名空间
  state: {
    categories:[],
    tags:[]
  },
  mutations: {
    SET_categories(state,value) {
      state.categories = value
    },
    SET_tags(state,value) {
      state.tags = value
    },
  },
  actions: {
    async updateCategories(context) {
      // axios.get('/category').then(res => context.commit('SET_categories',res.data))
      let res = await getAllCategory()
      context.commit('SET_categories',res.data)
    },
    async updateTags(context) {
      let res = await getAllTag()
      context.commit('SET_tags',res.data)
    }
  },
  getters: {
    
  }
}


const User = {
  namespaced: true,	// 开启命名空间
  state: {
    isLogin:false,
    user:null,
  },
  mutations: {
    SET_userinfo(state,value) {
      state.user = value
    },
    SET_email(state,value) {
      console.log(value)
      state.user.email = value
    },
    SET_nickname(state,value) {
      state.user.nickname = value
    },
    SET_blogUrl(state,value) {
      state.user.blogUrl = value
    },
    SET_sign(state,value) {
      state.user.sign = value
    },
    SET_isLogin(state,value) {
      state.isLogin = value
    }
  },
  actions: {
    async login(context) {
      // 使用之前登录保存的token登录
      if (localStorage.token!=null) {
        let res = await loginByToken(localStorage.token)
        context.commit('SET_isLogin',true)
        context.commit('SET_userinfo',res.data)
        Notification.success({
          title:'登录成功'
        })
      }
    },
  },
  getters: {
    
  }
}

export default new Vuex.Store({
  modules: {
    Cache,
    User
  }
})
