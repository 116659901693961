<template>
  <div id="layout">

    <!-- 左侧导航 -->
    <div class="left flex-column-start-center">
      <div class="top md">
        <img src="https://q.qlogo.cn/g?b=qq&nk=491705331&s=100" alt="" :style="logoShadow"> 
      </div>
      <div class="border md"></div>
      <nav class="nav flex-column-start-center">
        <router-link to="/articles" class="flex-row-center" active-class="active">
          <bookshelf v-if="$route.path.indexOf('/articles')!=-1" theme="multi-color" :size="iconSize"
            :fill="['#333' ,'#2F88FF' ,'#FFF' ,'#FFF']" :strokeWidth="3" />
          <bookshelf v-else theme="two-tone" :size="iconSize" :fill="['#333' ,'#e0e4e9']" :strokeWidth="3" />
          <span> 文章</span>
        </router-link>

        <router-link to="/projects" class="flex-row-center" active-class="active">
          <waterfalls-h v-if="$route.name=='Projects'" theme="multi-color" :size="iconSize"
            :fill="['#333' ,'#2F88FF' ,'#FFF' ,'#FFF']" :strokeWidth="3" />
          <waterfalls-h v-else theme="two-tone" :size="iconSize" :fill="['#333' ,'#e0e4e9']" :strokeWidth="3" />
          <span> 项目</span>
        </router-link>

        <router-link to="/outline" class="flex-row-center" active-class="active">
          <calendar v-if="$route.name=='Outline'" theme="multi-color" :size="iconSize"
            :fill="['#333' ,'#2F88FF' ,'#FFF' ,'#FFF']" :strokeWidth="3" />
          <calendar v-else theme="two-tone" :size="iconSize" :fill="['#333' ,'#e0e4e9']" :strokeWidth="3" />
          <span> 归档</span>
        </router-link>
        <!-- 
        <router-link to="/about" class="flex-row-center" active-class="active">
          <notes v-if="$route.path.indexOf('/about')!=-1" theme="multi-color"  :size="iconSize" :fill="['#333' ,'#2F88FF' ,'#FFF' ,'#FFF']" :strokeWidth="3"/>
          <notes v-else theme="two-tone" :size="iconSize" :fill="['#333' ,'#e0e4e9']" :strokeWidth="3"/>
          <span>笔记</span>
        </router-link > -->

        <router-link to="/leaveWord" class="flex-row-center" active-class="active">
          <comment v-if="$route.name=='LeaveWord'" theme="filled" :size="iconSize+1" fill="#2F88FF" :strokeWidth="4" />
          <comment v-else theme="two-tone" :size="iconSize+1" :fill="['#333' ,'#e0e4e9']" :strokeWidth="3" />
          <span> 留言</span>
        </router-link>

        <router-link to="/aboutMe" class="flex-row-center" active-class="active">
          <winking-face v-if="$route.name=='AboutMe'" theme="filled" :size="iconSize+2" fill="#2F88FF"
            :strokeWidth="4" />
          <winking-face v-else theme="two-tone" :size="iconSize+2" :fill="['#333' ,'#e0e4e9']" :strokeWidth="3" />
          <span> 关于</span>
        </router-link>


        <router-link to="/profile" class="flex-row-center" active-class="active">
          <permissions v-if="$route.path.indexOf('/profile')!=-1" theme="two-tone" :size="iconSize+1" fill="#2F88FF"
            :strokeWidth="4" />
          <permissions v-else theme="two-tone" :size="iconSize+1" :fill="['#333' ,'#e0e4e9']" :strokeWidth="3" />
          <span> 我的</span>
        </router-link>

        <router-link v-if="user!=null&&user.isWebAdmin" to="/postArticle" class="flex-row-center" active-class="active">
          <email-push v-if="$route.name=='PostArticle'" theme="filled" :size="iconSize" fill="#2F88FF"
            :strokeWidth="4" />
          <email-push v-else theme="two-tone" :size="iconSize" :fill="['#333' ,'#e0e4e9']" :strokeWidth="3" />
          <span> 发布</span>
        </router-link>

        <router-link v-if="user!=null&&user.isWebAdmin" to="/system" class="flex-row-center" active-class="active">
          <config v-if="$route.path.indexOf('/system')!=-1" theme="filled" :size="iconSize+1" fill="#2F88FF"
            :strokeWidth="4" />
          <config v-else theme="two-tone" :size="iconSize+1" :fill="['#333' ,'#e0e4e9']" :strokeWidth="3" />
          <span> 系统</span>
        </router-link>

      </nav>
      <div class="bottom md">
      </div>
    </div>

    <!-- 右侧 -->
    <div class="right flex-column-start-center">
      <!-- 头部 -->
      <div class="header flex-row-center">
        <!-- 搜索 -->
        <div class="search center">
          <input type="search" autocomplete="false" v-model="articleSearch" placeholder="搜索更多文章内容">
        </div>
        <!-- 其他 -->
        <div class="profile">
          <!-- 登录按钮 -->
          <div class="detail center" @click="login" v-if="!isLogin"> 
            <!-- btn-login类 -->
            <github-one theme="filled" size="16" fill="#000" class="center" />github登录
          </div>
          <template v-if="isLogin">
            <!-- 用户信息 -->
            <div class="detail flex-row-center user-info" @click="$router.push('/profile/info')">
              <div class="img-wrap"><img :src="user.avatar" alt=""></div>
              <div class="name">{{user.nickname}}</div>
            </div>
            <!-- 消息 -->
            <div class="detail center notify" @click="$router.push('/profile/userNotify')">
              <message-unread size="18" theme="filled" fill="#555" />
              <span class="red-dot" v-show="unReadCount>0">{{unReadCount}}</span>
            </div>
          </template>
          <div v-if="isDarkTheme" class="detail center theme" @click="lightTheme()">
            <sun style="height: 19px;margin: 0 6px;" theme="filled" size="19" fill="#666"/>
          </div>
          <div v-else class="detail center theme" @click="darkTheme()">
            <moon style="height: 18px;margin: 0 6px;" theme="filled" size="18" fill="#666"/>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <!-- 主体内容 -->
      <div class="main">
        <keep-alive :include="['Articles','Outline','Projects','AboutMe','LeaveWord']">
          <router-view :articleSearch="articleSearch"></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { Notes, Calendar, WaterfallsH, Bookshelf, Comment, WinkingFace, EmailPush, Config, Permissions, MessageUnread,GithubOne,Moon,Sun } from '@icon-park/vue'
import { mapState } from 'vuex';
import { getUnReadCount } from '@/apis/notify';
import {
    enable as enableDarkMode,
    disable as disableDarkMode,
    auto as followSystemColorScheme,
    exportGeneratedCSS as collectCSS,
    isEnabled as isDarkReaderEnabled
} from 'darkreader';

export default {
  name: 'LayOut',
  components: {
    Notes, Calendar, Bookshelf, WaterfallsH, Comment, WinkingFace, EmailPush, Config, Permissions, MessageUnread, GithubOne, Moon,Sun
  },
  data() {
    return {
      iconSize: 18,
      unReadCount: 0,
      articleSearch:null,
      lastGetReadCountTime:null,
      logoShadow:{
        boxShadow: '0 0 7px 1px '+localStorage.bodyBgColor || 'rgba(30, 144, 255, 0.2)',
        backgroundColor: '#eee !important'
      },
      isInitRouter:false,
      isDarkTheme: false
    }
  },
  methods: {
    // 绑定登录按钮点击事件
    login() {
      if (!this.$store.state.User.isLogin) {
        let href = window.location.href
        let i = href.indexOf("//") + 2 
        let index = href.indexOf("/",i)
        let domain = href.substring(0,index)
        const clientId = "8ac933fec25906842623"
        const redirectUri = `${domain}/#/articles`
        window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}`
      }
    },
    // 初始化管理员路由 
    initRouter() {
      if (this.isInitRouter) {
        return;
      }
      let isWebAdmin = this.$store.state.User.user !== null && this.$store.state.User.user.isWebAdmin
      if (isWebAdmin) {
        let postArticle = { // 发布文章
              path: '/postArticle',
              name: 'PostArticle',
              component: () => import('@/views/article/postArticle.vue')
        }
        let adminRouter = { // 系统
            path: '/system',
            name: 'System',
            redirect: '/system/index',
            component: () => import('@/views/system/systemLayout'),
            children: [
              {
                path: 'index',
                name: 'SystemName',
                component: () => import('@/views/system/index'),
              },
              {
                path: 'articleManage',
                name: 'ArticleManage',
                component: () => import('@/views/system/articleManage'),
              },
              {
                path: 'commentManage',
                name: 'CommentManage',
                component: () => import('@/views/system/commentManage'),
              },
              {
                path: 'categoryAndTagManage',
                name: 'CategoryAndTagManage',
                component: () => import('@/views/system/categoryAndTagManage'),
              },
              {
                path: 'friendUrlManage',
                name: 'FriendUrlManage',
                component: () => import('@/views/system/friendUrlManage'),
              },
              {
                path: 'userManage',
                name: 'UserManage',
                component: () => import('@/views/system/userManage'),
              },
              {
                path: 'optionManage',
                name: 'OptionManage',
                component: () => import('@/views/system/optionManage'),
              },
            ]
        }
        this.$router.addRoute(postArticle)
        this.$router.addRoute(adminRouter)
        this.isInitRouter = true;
      }
    },
    getUnReadCount(){
      if (this.$store.state.User.isLogin) {
        getUnReadCount().then(res => {
          if (res.data == null ) return;
          this.lastGetReadCountTime = +new Date();
          this.unReadCount = res.data.selfCount + res.data.systemCount
        })
      }
    },
    darkTheme() {
      localStorage.setItem('theme','dark')
      this.isDarkTheme = true
      enableDarkMode({
          brightness: 90,
          contrast: 100,
          sepia: 0,
          grayscale: 50
      });
    },
    lightTheme () {
      localStorage.removeItem('theme')
      disableDarkMode();
      this.isDarkTheme = false
    },
    initIsDarkTheme() {
      if (localStorage.getItem('theme')=='dark') {
        this.darkTheme()
      }
    }
  },
  mounted() {
    this.getUnReadCount()
    this.initRouter()
    this.initIsDarkTheme()
  },
  computed: {
    ...mapState('User', ['user', 'isLogin'])
  },
  // 生命周期
  updated() { //更新收到消息 
    this.initRouter()
    let nowTime = +new Date();
    let offset = nowTime - this.lastGetReadCountTime
    if (offset/1000/60 > 0.5) { // 已过0.5分钟
      this.getUnReadCount()
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@/static/css/style.scss';

$header-height: 60px;
$left-width: 110px;

#layout {

  .left {
    border-right: 1px solid #eee;
    transition: all .4s;
    width: $left-width;
    .top {
      height: $header-height;
      padding: 4px;
      margin-bottom: 8px;
      background-color: #fff !important;

      img {
        width: $header-height - 8px;
        height: $header-height - 8px;
        padding: 0px;
        background-color: #fff !important;
        border-radius: 6px;
      }
    }

    nav {
      flex: 1;
      width: 100%;
      margin-top: 8px;
      overflow: auto;

      .i-icon {
        height: 18px;
      }

      .active {
        color: black;
      }

      a {
        height: 50px;
        min-height: 40px;
        width: 100%;
        min-width: 58px;
        color: rgba(44, 62, 80, 0.6);
        text-decoration: none;
        

        &:hover {
          background-color: rgba(238, 238, 238, 0.538);
        }
      }

      img {
        height: 32px;
        width: 32px;
      }

      span {
        margin-left: 4px;
      }
    }

    .bottom {
      width: 100%;
      height: 0px;
    }
    
  }

  .right {
    height: 100%;
    flex: 1;

    .header {
      width: 100%;
      height: $header-height;
      margin-bottom: 8px;
      padding: 0 12px;

      .search {
        flex: 3;

        input {
          width: 100%;
          height: 100%;
          padding: 12px;
          font-weight: 500;
          color: black;
          border-radius: 12px;
          background-color: rgb(246, 249, 250);
          padding-left: 18px;

          &:focus {
            border: 1px solid #ddd;
          }
        }
      }

      .profile {
        flex: 2;
        display: flex;
        height: 40px;
        flex-direction: row-reverse;
        align-items: center;

        .btn-login {
          padding: 4px 10px;
          border-radius: 4px;
          background-color: #eee;
          border: 1px solid #ccc;
          cursor: pointer;
        }

        .detail {
          margin: 0 6px;
          padding: 4px 12px;
          height: 100%;
          border-radius: 8px;
          background-color: #f6f9fa;
          border: 1px solid transparent;
          cursor: pointer;

          &:hover {
            border: 1px solid #ddd;
          }
        }
        .theme {
          padding: 4px;
        }

        .user-info {
          .img-wrap {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            overflow: hidden;
          }

          .name {
            font-size: 14px;
            font-weight: 600;
            margin: 0 6px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1; /* 这里是超出几行省略 */
            overflow: hidden;
          }
        }

        .notify {
          position: relative;
          span {
            height: 18px;
          }
          svg {
            height: 20px !important;
            width: 20px !important;
          }

          .red-dot {
            height: 14px;
            line-height: 14px;
            text-align: center;
            display: inline-block;
            padding: 0 4px;
            border-radius: 50%;
            position: absolute;
            top: -3px;
            right: -3px;
            background-color: red;
            color: white;
            font-size: 12px;
            box-shadow: 0px 0px 1px 2px #d6d6d6;
          }
        }
      }
    }

    .border {
      width: 98%;
    }

    .main {
      width: 100%;
      flex: 1;
      overflow: auto;
      transform: scale(1);
    }
  }

  font-size: 14px;
  position: fixed;
  padding: 14px 0;
  display: flex;
  justify-content: center;
  align-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $font-dark-color;
  background-color: white;
  border-radius: 18px;
  box-shadow: 1px 1px 4px 2px #ddd;
  transition: all .4s;
}
 // github语言
//电脑 大于760
@media screen and (min-width:760px){
    #layout {
      min-width: 760px;
      top: 12px;
      bottom: 12px;
      left: 140px;
      right: 140px;
    }
}
// 平板开始全屏 160+160 = 320 (两侧占用宽带)
@media screen and (max-width:1220px){
    #layout {
      top: 6px;
      bottom: 0px;
      left: 6px;
      right: 6px;
      .left {
        width: 75px;
      }
    }
}

// 手机布局
@media screen and (max-width:620px){
    #layout {
      flex-flow: column-reverse;
      .left {
        width: auto;
        position: fixed;
        right: 6px;
        left: 6px;
        z-index: 99;
        bottom: 0;
        background-color: white;
        .nav {
          flex-direction: row;
          margin-top: 0;
        }
      }
      .right {
        padding-bottom: 50px - 14px;
        .header {
          height: 22px;
          .search {
            padding-right: 20px;
            input {
              padding: 6px;
            }
          }
          .profile {
            .detail {
              height: 28px;
              margin: 0 2px;
              padding: 2px 6px;
            }
            .notify {
                span {
                  height: 15px;
                }
                svg {
                  height: 15px;
                  width: 15px;
                }
              }
            .theme {
              padding: 2px;
            }
            .user-info {
              .img-wrap {
                height: 22px;
                width: 22px;
              }
              .name {
                font-weight: 500 !important;
                font-size: 13px !important;
              }
            }
            .btn-login {
              font-size: 12px;
              padding: 4px;
            }
          }
        }
      }
    }  
    .md {
      display: none !important;
    }
}
</style>
