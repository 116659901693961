import myAxios from "./axiosConfig";

export function getSelfNotify (page,size) {
    return myAxios({
        url:`/notify/${page}/${size}`,
        method:'get',
        params: {
            page,size
        }
    })
}

export function getSystemNotify (page,size) {
    return myAxios({
        url:`/notify/system/${page}/${size}`,
        method:'get',
        params: {
            page,size
        }
    })
}


export function getUnReadCount () {
    return myAxios({
        url:`/notify/unReadCount`,
        method:'get',
    })
}

// 所有私信设为已读
export function readAllSelfNotify () {
    return myAxios({
        url:`/notify/readAll`,
        method:'put'
    })
}
// 单私信设为已读
export function readOneSelfNotify (id) {
    return myAxios({
        url:`/notify/read/${id}`,
        method:'put',
    })
}

// 所有系统通知设为已读
export function readAllSystemNotify () {
    return myAxios({
        url:`/notify/system/readAll`,
        method:'put'
    })
}
// 单条系统通知设为已读
export function readOneSystemNotify (id) {
    return myAxios({
        url:`/notify/system/read/${id}`,
        method:'put',
    })
}

// 单条系统通知设为已读
export function createSystemNotify (toUserId,content) {
    return myAxios({
        url:`/notify/system`,
        method:'post',
        params: {
            toUserId,
            content
        }
    })
}